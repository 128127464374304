/* eslint-disable */
import get from 'lodash/get'
import moment from 'moment'
import formatMoney from '../formatMoney'
import { getBulkAccounts } from '../../containers/PaymentsBulk/selectors'
export interface TidyPaymentsBulkSearchD {
  data: {
    fileDetails: {
      fileUpload: {
        dictionaryArray: {
          nameValuePairDTOArray: {
            genericName: string
            value: string
          }[]
        }[]
        createdBy: string
        creationDate: string
        lastUpdatedBy: string
        lastUpdatedDate: string
        version: number
        generatedPackageId: boolean
        auditSequence: number
        fileIdentifier: string
        successRecords: number
        fileHash: string
        failedRecords: number
        totalRecords: number
        attachmentId: string
        key: { id: string }
        fileStatus: string
        fileSize: string | number
        totalAmount: {
          amount: number
          currency?: string
        }
        fileName: string
        mimeType: string
        fileType: string
        fileContent: string
        fileHistory: {
          version: number
          generatedPackageId: boolean
          auditSequence: number
          stepId: string
          startDate: string
        }[]
        transaction: string
        fileIdentifierDescription: string
        filePartiallyProcessed: boolean
      }
    }[]
  }
}
export default (
  data: TidyPaymentsBulkSearchD,
  state: {
    accounts: {
      realToMasked: any // puting any here as here key from the backend can be either string or number
    }
  }
) => {
  const items = {}
  const { realToMasked } = state.accounts
  const bulkAccounts = getBulkAccounts(state)
  const accountsNumbersMap = bulkAccounts.reduce(
    (accountMap, account) => ((accountMap[account.accountNumber] = true), accountMap),
    {}
  )

  let list = get(data, 'data.fileDetails')

  if (!list) return { items }

  if (!(list instanceof Array)) {
    list = [list]
  }

  list.forEach((item) => {
    const file = item.fileUpload
    const { id } = file.key
    const {
      fileName,
      attachmentId,
      dictionaryArray,
      totalRecords,
      fileStatus,
      totalAmount,
      creationDate,
    } = file
    let newTotalRecords = totalRecords
    let debitAccount = dictionaryArray[0].nameValuePairDTOArray.filter(
      (nameValueItem) =>
        nameValueItem.genericName ===
        'com.ofss.digx.cz.domain.fileupload.entity.CZFileDetails.debitAccountId'
    )[0].value
    if (debitAccount.includes('@~')) {
      debitAccount = debitAccount.split('@~')[1]
    }
    const date =
      creationDate && creationDate !== '' && moment(creationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

    let amount = formatMoney(totalAmount.amount, 'GBP')
    let fromId = realToMasked[debitAccount]
    if (file.fileStatus === 'ERROR') {
      amount = undefined
      newTotalRecords = undefined
      fromId = undefined
    }
    if (accountsNumbersMap[debitAccount]) {
      items[attachmentId] = {
        id,
        fileName,
        transactionCount: newTotalRecords,
        fromId,
        status: fileStatus,
        amount,
        date,
      }
    }
  })
  return {
    items,
  }
}
